import axios from "axios";
import { API_URL } from "./config.js";
import { apiCall } from "./auth";

//getservicesdata
export const getbookingservices = (usertype) => apiCall(`${API_URL}getfrontbookingserviceslist?usertype=${usertype}`, "GET",);

//getsubservicebyserives
export const getsubservicebyservice = (formData) => apiCall(`${API_URL}getsubservicebyservice`, "POST", formData);

//getstafflist 
export const getstafflist = () => apiCall(`${API_URL}getstafflist`, "GET",);

//getstafflist 
export const getstaffTime = (date, staffid) => apiCall(`${API_URL}getbookings?start_date=${date}&staffid=${staffid}`, "GET",);

//getaddon 
export const getaddon = (formData) => apiCall(`${API_URL}getaddonbyaservice`, "POST", formData);

//getuser 
export const getuser = (formData) => apiCall(`${API_URL}frontlogin`, "POST", formData);

//forgetpasswordapi 
export const forgetpasswordapi = (formData) => apiCall(`${API_URL}forgetpassword`, "POST", formData);

//getuser 
export const SignUp = (formData) => apiCall(`${API_URL}signup`, "POST", formData);

//getuser 
export const addbooking = (formData) => apiCall(`${API_URL}addbookingforuser`, "POST", formData);

//getstafflist 
export const getstafflistbyzone = (zone) => apiCall(`${API_URL}getstafflistbyzone?zone=${zone}`, "GET",);

//getstafflist 
export const getstaffslotdatabyzone = (date,zone) => apiCall(`${API_URL}getstaffslotdatabyzone?zone=${zone}&date=${date}`, "GET",);

//getuserdetails 
export const getuserdetails = (formData) => apiCall(`${API_URL}getuserdetails`, "POST",formData);

//getsearchpricestatus 
export const getsearchpricestatus = (formData) => apiCall(`${API_URL}getsearchpricestatus`, "POST",formData);

//getreport 
export const getreport = (formData) => apiCall(`${API_URL}getreport`, "POST",formData);

export const getbookingdataNew = (formData) => apiCall(`${API_URL}getbookingdataNew`, "POST",formData);


//updateBookingData
export const updateBookingData = (formData) => apiCall(`${API_URL}frontupdateBookingDetails`, "POST", formData);


export const getpricingdata = (formData) => apiCall(`${API_URL}getpricingdata`, "POST", formData);